import React from 'react';
import {FaInstagram} from 'react-icons/fa';
import { FooterContainer, SocialIconLink, SocialIcons, SocialMedia, SocialMediaWrap, WebsiteRights } from './FooterStyles';

const Footer = () => {
  return (
    <FooterContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <WebsiteRights>
                        Built Investments © {new Date().getFullYear()}  All rights reserved.
                    </WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href='//www.instagram.com/chris.boutte' targets='_blank' aria-label='Instagram'>
                            <FaInstagram />
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
    </FooterContainer>
  )
}

export default Footer