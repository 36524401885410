import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import { AgentContainer, AgentForm, Column1, Column2, FormInput, FormTextArea,
  InputGroup1, InputGroup2, InputGroup3,
  InputName,
SubmitButton, SubmitWrap, Text, TextWrapper} from './AgentStyles'

const Agent = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_urxlbk3', 'Built_Investments_Agent', form.current, 'user_yrN7yNcYIyZU1KVM2KExi')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <AgentContainer>
        <Column1>
          <TextWrapper>
            <Text>
              Whether you want to sell your home or are looking for your first or next forever home, we'd love to help!
              Please fill out the contact form and one of our agents will contact you as soon as possible.
            </Text>
          </TextWrapper>
        </Column1>
        <Column2>
          <AgentForm ref={form} onSubmit={sendEmail}>
            <InputGroup1>
              <InputName>Name</InputName>
              <FormInput type='text' name='name'/>
            </InputGroup1>
            <InputGroup1>  
              <InputName>Email</InputName>
              <FormInput type='text' name='email'/>
            </InputGroup1>
            <InputGroup2>
              <InputName>Phone Number</InputName>
              <FormInput type='text' name='phone_number'/>
            </InputGroup2>
            <InputGroup2>
              <InputName>Property Address</InputName>
               <FormInput type='text' name='property_address'/>
            </InputGroup2>
            <InputGroup3>
              <InputName>Message</InputName>
              <FormTextArea type='text' name='message'/>
            </InputGroup3>
            <SubmitWrap>
              <SubmitButton>Submit</SubmitButton>
            </SubmitWrap>
          </AgentForm>
        </Column2>
    </AgentContainer>
  )
}

export default Agent