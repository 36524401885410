import React from 'react'
import {BsHouseDoorFill} from 'react-icons/bs';
import { TitleContainer, BuiltH1, InvestmentsH1, HouseIcon, TitleWrapper } from './TitleStyles'

const Title = () => {
  return (
    <TitleContainer>
        <TitleWrapper>
            <BuiltH1>
              Built
            </BuiltH1>
            <HouseIcon>
              <BsHouseDoorFill />
            </HouseIcon>
            <InvestmentsH1>
              Investments
            </InvestmentsH1>
        </TitleWrapper>
    </TitleContainer>
  )
}

export default Title