import React, {useRef} from 'react'
import emailjs from '@emailjs/browser';
import { CashContainer, CashForm, Column1, Column2, Text, TextWrapper, FormInput, FormTextArea, 
  SubmitButton, InputGroup1, InputGroup2, InputGroup3, InputName, SubmitWrap } from './CashStyles'

const Cash = () => {
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_urxlbk3', 'Built_Investments_Agent', form.current, 'user_yrN7yNcYIyZU1KVM2KExi')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  
  return (
    <CashContainer>
        <Column1>
          <TextWrapper>
            <Text>
              Need to sell fast? Need repairs? Is your home a headache?
              Receive a cash offer for your property AS-IS. No repairs, no agent fees and no closing costs!
            </Text>
          </TextWrapper>
        </Column1>
        <Column2>
          <CashForm ref={form} onSubmit={sendEmail}>
            <InputGroup1>
              <InputName>Name</InputName>
              <FormInput type='text' name='name'/>
            </InputGroup1>
            <InputGroup1>  
              <InputName>Email</InputName>
              <FormInput type='text' name='email'/>
            </InputGroup1>
            <InputGroup2>
              <InputName>Phone Number</InputName>
              <FormInput type='text' name='phone_number'/>
            </InputGroup2>
            <InputGroup2>
              <InputName>Property Address</InputName>
               <FormInput type='text' name='property_address'/>
            </InputGroup2>
            <InputGroup3>
              <InputName>Message</InputName>
              <FormTextArea type='text' name='message'/>
            </InputGroup3>
            <SubmitWrap>
              <SubmitButton>Submit</SubmitButton>
            </SubmitWrap>
          </CashForm>
        </Column2>
    </CashContainer>
  )
}

export default Cash