import React from 'react'
import {AboutContainer, TextWrapper, Text} from './AboutStyles'


const About = () => {
  return (
    <AboutContainer>
          <TextWrapper>
            <Text>
              Built Investments aims to help those with all real estate needs. If you're buying, selling no matter how complex the situation, we can help!
              We focus on finding the win-win, so that everyone walks away with a smile.
            </Text>
          </TextWrapper>
    </AboutContainer>
  )
}

export default About