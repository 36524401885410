import styled from 'styled-components';

export const FooterContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5vh;
    width: 100vw;
    padding: 0;
    margin: 0;
`;


export const SocialMedia = styled.section`
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 0;
    position: absolute;
    top: 30px;
`;

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const WebsiteRights = styled.small`
    margin: 0;
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-self: center;
`;

export const SocialIconLink = styled.a`
    color: black;
    font-size: 18px;
    align-self: center;

    &:hover {
        font-size: 22px;
        color: #fff;
        transition: 0.3s ease-out;
    }
`;