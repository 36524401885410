import styled, {keyframes} from "styled-components";

export const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 10vh;
    width: 100vw;
    position: relative;

    @media screen and (max-width: 1400px) {
        height: 8vh;
    }

    @media screen and (max-width: 768px) {
        height: 5vh;
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const houseIn = keyframes`
    0%{
        transform: rotateX(90deg);
    }
    100%{
        transform: rotateX(0deg);
    }
`;

export const HouseIcon = styled.div`
    align-self: center;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 3vh;
    color: rgb(42, 55, 56, .8);
    animation: ${houseIn} 3s forwards ease-in-out;

    @media screen and (max-width: 1400px) {
        padding-top: 20px;
    }

    @media screen and (max-width: 768px) {
        padding-top: 25px;
        font-size: 2.5vh;
    }

    @media screen and (max-width: 590px) {
        padding-top: 10px;
        font-size: 2vh;
    }
`;

const builtIn = keyframes`
    0%{
        transform: rotateY(90deg);
    }
    100%{
        transform: rotateY(0deg);
    }
`;

export const BuiltH1 = styled.h1`
    letter-spacing: 4px;
    font-size: 8vh;
    margin: 0;
    animation: ${builtIn} 1.5s forwards ease-in-out;

    @media screen and (max-width: 768px) {
        font-size: 6vh;

    }

    @media screen and (max-width: 590px) {
        font-size: 5vh;
    }
`;

const investmentsIn = keyframes`
    0%{
        transform: rotateY(-90deg);
    }
    100%{
        transform: rotateY(0deg);
    }
`;

export const InvestmentsH1 = styled.h1`
    letter-spacing: 4px;
    padding-left: 5px;
    margin: 0;
    font-size: 8vh;
    animation: ${investmentsIn} 1.5s forwards ease-in-out;

    @media screen and (max-width: 768px) {
        font-size: 6vh;
    }

    @media screen and (max-width: 590px) {
        font-size: 5vh;
    }
`;