import styled, {keyframes} from 'styled-components';


export const HeroContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr 1fr;
    height: 80vh;
    width: 100vw;

    @media screen and (max-width: 1400px) {
      grid-template-rows: 1fr 4fr;
    }
`;

export const NavContainer = styled.nav`
    grid-column: 1 / 2;
    grid-row: 1 / -1;
    
    @media screen and (max-width: 1400px) {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    list-style-type: none;
    align-items: center;
    padding-left: 20px;

    @media screen and (max-width: 1400px) {
        padding-left: 0;
    }
`;

const navIn = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1000px);
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
`;

export const AboutNav = styled.li`
    display: flex;
    justify-content: center;
    padding: 10px 10px;
    margin: 10px 10px;
    border: 1px solid black;
    cursor: pointer;
    height: 5vh;
    width: 100%;
    border-radius: 5px;
    animation: ${navIn} 2.5s forwards ease-in;

    &:hover {
        background-color: rgb(99, 77, 74, .5);
        border: 2px solid black;
    }
    
    @media screen and (max-width: 1400px) {
        height: 100%;
        width: 50%;
        padding: 2px 0px;
        margin: 2px 0px;
    }

`;

export const AgentNav = styled.li`
    display: flex;
    justify-content: center;
    padding: 10px 10px;
    margin: 10px 10px;
    border: 1px solid black;
    cursor: pointer;
    height: 5vh;
    width: 100%;
    border-radius: 5px;
    animation: ${navIn} 1.5s forwards ease-in;

    &:hover {
        background-color: rgb(99, 77, 74, .5);
        border: 2px solid black;
    }

    @media screen and (max-width: 1400px) {
        height: 100%;
        width: 70%;
        padding: 2px 0px;
        margin: 2px;
    }
`;

export const CashNav = styled.li`
    display: flex;
    justify-content: center;
    padding: 10px 10px;
    margin: 10px 10px;
    border: 1px solid black;
    cursor: pointer;
    height: 5vh;
    width: 100%;
    border-radius: 5px;
    animation: ${navIn} 2s forwards ease-in;

    &:hover {
        background-color: rgb(99, 77, 74, .5);
        border: 2px solid black;
    }

    @media screen and (max-width: 1400px) {
        height: 100%;
        width: 60%;
        padding: 2px 0px;
        margin: 0px 0px;
    }
`;


export const ElementContainer = styled.div`
    grid-column: 2 / 3;
    grid-row: 1 / -1;
    height: 100%;

    @media screen and (max-width: 1400px) {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
    }
`;

export const ActiveElement = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px 20px;
`;