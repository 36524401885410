import styled, {keyframes} from 'styled-components';
import agentPic from '../../Images/agentPic.jpg';

const containerIn = keyframes`
    0%{
        opacity: 0;
        transform: scaleX(0);
    }
    100% {
        opacity: 1;
        transform: scaleX(1);
    }
`;


export const AgentContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr 3fr;
    grid-template-columns: 1fr 3fr;
    background-image: url(${agentPic});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 70vh;
    border-radius: 50px;
    border: 1px solid black;
    animation: ${containerIn} 1s forwards ease-in-out;

    @media screen and (max-width: 1400px) {
        height: 65vh;
    }
`;

export const Column1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 1 / 2;
    grid-row: 1 / -1;
    padding-left: 30px;

    
    @media screen and (max-width: 790px) {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        flex-direction: row;
        padding: 0px 10px;
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 20vh;
    width: 100%;
`;

export const Text = styled.h3`
    padding: 10px;
    font-size: 24px;
    font-weight: lighter;
    background-color: rgb(74, 96, 99, 0.8);
    box-shadow: 0 0 15px 0 rgb(74, 96, 99, 0.8);
    border-radius: 10px;
    color: black;

    @media screen and (max-width: 767px) {
        font-weight: lighter;
        font-size: 16px;
    }

    @media screen and (max-width: 350px) {
        font-size: 12px;
    }
`;

export const Column2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 2 / 3;
    grid-row: 1 / -1;
    padding: 20px;
    
    @media screen and (max-width: 790px) {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
        flex-direction: row;
        padding: 0;
    }
`;

export const AgentForm = styled.form`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 2fr 1fr;
    grid-row-gap: 3px;
    align-items: center;
    height: 90%;
    width: 90%;
    background-color: rgb(74, 96, 99, 0.8);
    box-shadow: 0 0 15px 0 rgb(74, 96, 99, 0.8);
    border-radius: 25px;

    @media screen and (max-width: 790px) {
        height: 80%;
    }
    
    @media screen and (max-width: 479px) {
        height: 70%;
    }

    @media screen and (max-width: 375px) {
        height: 65%;
    }
`;

export const InputGroup1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    grid-row: 1 / 2;
    height: 100%;
    width: 100%;
    padding: 20px;
`;

export const InputGroup2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    grid-row: 2 / 3;
    height: 100%;
    width: 100%;
    padding: 20px;

    @media screen and (max-width: 375px) {
        padding-top: 0px;
    }
`;

export const InputGroup3 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    grid-row: 3 / 4;
    grid-column: 1 / -1;
    height: 100%;
    width: 100%;
    padding: 10px 20px;

    @media screen and (max-width: 380px) {
        padding-top: 0px;
    }
`;

export const SubmitWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    grid-row: 4 / 5;
    grid-column: 1 / -1;
`;

export const InputName = styled.label`
    font-size: 14px;
`;

export const FormInput = styled.input`
    background-color: rgb(137, 179, 183, .6);
    border-radius: 5px;
    border: 0;
    outline: 0;
    grid-row: 1 / -1;
    animation-fill-mode: forwards;
    width: 100%;

    &:focus {
        border: 1px solid white;
    }
`;

export const FormTextArea = styled.textarea`
    background-color: rgb(137, 179, 183, .6);
    border: 0;
    outline: 0;
    border-radius: 5px;
    grid-row: 1 / -1;
    resize: none;
    width: 100%;
    height: 100%;

     &:focus {
        border: 1px solid white;
    }
`;

export const SubmitButton = styled.button`
    border-radius: 5px;
    background-color: rgb(169, 220, 226, .5);
    width: 50%;
    align-self: center;
    cursor: pointer;
    border: none;
    box-shadow: 0 0 15px 0 rgb(42, 55, 56, .8);

    &:hover {
        background-color: rgb(99, 77, 74, .8);
    }
`;
