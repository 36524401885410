import React, {useState} from 'react'
import About from '../Elements/About/About'
import Agent from '../Elements/Agent/Agent'
import Cash from '../Elements/Cash/Cash'
import { AboutNav, ActiveElement, AgentNav, CashNav, ElementContainer, HeroContainer, NavContainer, NavMenu } from './HeroStyles'

const Hero = () => {

    const [activeElement, setActiveElement] = useState(<About/>)

  return (
        <HeroContainer>
            <NavContainer>
                <NavMenu>
                    <AgentNav onClick={() => setActiveElement(<Agent/>)}>
                        Need an agent?
                    </AgentNav>
                    <CashNav onClick={() => setActiveElement(<Cash/>)}>
                        Want a cash offer?
                    </CashNav>                    
                    <AboutNav onClick={() => setActiveElement(<About/>)}>
                        Who we are
                    </AboutNav>
                </NavMenu>
            </NavContainer>
            <ElementContainer>
                <ActiveElement>
                    {activeElement}
                </ActiveElement>
            </ElementContainer>
        </HeroContainer>
  )
}

export default Hero