import React from 'react';
import Hero from '../Hero/Hero';
import Title from '../Title/Title';
import Footer from '../Footer/Footer';

const Landing = () => {


  return (
        <>
          <Title/>
          <Hero/>
          <Footer/>
        </>
  )
}

export default Landing