import styled, {keyframes} from 'styled-components';
import BuiltPic from '../../Images/built-bg.jpg';

const containerIn = keyframes`
    0%{
        opacity: 0;
        transform: scaleX(0);
    }
    100% {
        opacity: 1;
        transform: scaleX(1);
    }
`;

export const AboutContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    background-image: url(${BuiltPic});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 70vh;
    border-radius: 50px;
    border: 1px solid black;
    animation: ${containerIn} 1s forwards ease-in-out;

    @media screen and (max-width: 1400px) {
        height: 65vh;
    }
`;


export const TextWrapper = styled.div`
    grid-column: 1 / -1;
    grid-row: 3 / -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow: clip;
    z-index: -100;
    padding: 5px;
    
`;

export const Text = styled.h3`
    padding: 10px;
    letter-spacing: 1px;
    font-weight: lighter;
    word-spacing: 1px;
    line-height: 32px;
    font-size: 24px;
    background-color: rgb(10, 14, 14, 0.5);
    box-shadow: 0 0 15px 0 rgb(10, 14, 14, 0.5);
    border-radius: 20px;
    color: white;

    @media screen and (max-width: 767px) {
        line-height: 16px;
        font-size: 18px;     
}
`;
